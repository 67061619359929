<template>
  <div class="bulkComm-detail" style="background-color: #f8fcff">
    <BannerTItle :commodityDetailData="commodityDetailData" />
    <div class="content">
      <el-form
        ref="form"
        class="bulkComm-form"
        :model="form"
        label-position="left"
        label-width="100px"
      >
        <el-form-item
          :rules="[{ required: true, message: '请输入报价' }]"
          prop="Price"
          label="请输入报价"
        >
          <el-input placeholder="请输入报价" v-model="form.Price"></el-input>
        </el-form-item>
        <el-form-item
          class="formCode"
          :rules="formCodeRule"
          prop="code"
          label="验证码"
        >
          <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
          <div class="coderight" @click="refreshCode">
            <Identify :identifyCode="identifyCode" @click="Refresh" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="clickCreate('form')" class="btn"
            >提交报价</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import BannerTItle from "./bannerTItle.vue";
import Identify from "@/components/Identify";
import { mapState } from "vuex";
export default {
  name: "fillInTheOrder",
  components: {
    BannerTItle,
    Identify
  },
  data() {
    return {
      form: {
        Price: "",
        code: ""
      },
      formCodeRule: [
        {
          required: true,
          message: "请输入验证码"
        },
        {
          validator: (rules, value, callback) => {
            if (value !== this.identifyCode) {
              callback("请输入正确的验证码");
            }
            callback();
          }
        }
      ],
      identifyCode: "",
      identifyCodes: "1234567890abcdef"
    };
  },
  mounted() {
    this.makeCode(this.identifyCode, 4);
  },
  computed: {
    ...mapState({
      commodityDetailData: state => {
        return state.bulkCommodity.commodityDetailData;
      }
    })
  },
  created() {
    this.fetch();
  },
  methods: {
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    fetch() {
      this.$store.dispatch("bulkCommodity/getCommodityDetail", {
        ...this.$route.query
      });
    },
    refreshCode() {
      this.makeCode(this.identifyCode, 4);
    },
    Refresh(code) {},
    makeCode(o, l) {
      this.identifyCode = "";
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    clickCreate(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.form.code !== this.identifyCode) {
            this.$message({
              message: "验证码有误",
              type: "warning"
            });
            this.makeCode(this.identifyCode, 4);
            return;
          }
          this.$store
            .dispatch("bulkCommodity/createCommodity", {
              ...this.$route.query,
              Price: this.form.Price
            })
            .then(result => {
              this.$message({
                message: result.success ? "报价成功" : result.msg,
                type: result.success ? "success" : "warning"
              });
              if (result.success) {
                this.$router.push({
                  path: "/personalCenter/personalCenter",
                  query: {
                    value: 3
                  }
                });
              }
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.clearFix() {
  *zoom: 1;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.content {
  width: 1200px;
  margin: 100px auto 36px;
  box-shadow: 0px 0px 16px 0px rgba(71, 86, 159, 0.2);
  border-radius: 5px;
  background: #ffffff;
  padding: 80px 40px;
  .bulkComm-form {
    width: 40%;
    margin: 0 auto;
  }
  .btn {
    background: #24a2aa;
    color: white;
    width: 45%;
    height: 40px;
    margin-left: 17%;
  }
  .coderight {
    display: inline-block;
    margin-left: 10px;
  }
  .formCode {
    /deep/.el-form-item__content {
      display: flex;
    }
  }
}
</style>
