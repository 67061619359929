import { render, staticRenderFns } from "./particBidding.vue?vue&type=template&id=873873d2&scoped=true"
import script from "./particBidding.vue?vue&type=script&lang=js"
export * from "./particBidding.vue?vue&type=script&lang=js"
import style0 from "./particBidding.vue?vue&type=style&index=0&id=873873d2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "873873d2",
  null
  
)

export default component.exports